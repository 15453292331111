import Box from '@material-ui/core/Box'
import React from 'react'
import './productCard.scss'
import { classNames } from '../../utils/classNames'

export type ProductCardProps = {
  title: string
  description: string
  imageUrl: string
  url: string
}

type Props = {
  props: ProductCardProps
}

const ProductCard = ({ props }: Props) => {
  const { title, description, imageUrl, url } = props

  return (
    <Box className={classNames('productCard')}>
      <a href={url} className="productCard-link" />
      <img
        src={imageUrl}
        alt={title}
        height="256"
        className="productCard-image"
      />
      <Box className="productCard-content">
        <h2 className="productCard-title">{title}</h2>
        <p className="productCard-description">{description}</p>
      </Box>
    </Box>
  )
}

export default ProductCard
